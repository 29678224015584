import React, { createContext, ReactNode, useState } from 'react'

import FullPageLoading from '../components/FullPageLoading'

interface FullPageLoadingContextProps {
  isLoading: boolean
  setIsLoading: (value: boolean) => void
}

export const FullPageLoadingContext =
  createContext<FullPageLoadingContextProps>({} as FullPageLoadingContextProps)

interface FullPageLoadingProviderProps {
  children: ReactNode
}

export default function FullPageLoadingProvider({
  children,
}: FullPageLoadingProviderProps) {
  const [isLoading, setIsLoading] = useState(false)

  return (
    <FullPageLoadingContext.Provider value={{ isLoading, setIsLoading }}>
      {children}
      {isLoading && <FullPageLoading />}
    </FullPageLoadingContext.Provider>
  )
}
